<template>
    <!-- Single Blog Start -->
    <div class="single-blog">
        <div class="blog-image">
            <a href="javascript:;"><img :src="event.cover_photo" alt="Event"></a>
        </div>
        <div class="blog-content">
            <h4 class="title"><a href="javascript:;">{{ event.title }}</a></h4>

            <div class="blog-meta">
                <span> <i class="icofont-calendar"></i>{{ event.start_date }}</span>
            </div>

            <a href="javascript:;" class="btn btn-primary btn-hover-primary">View Details</a>
        </div>
    </div>
    <!-- Single Blog End -->
</template>
<script>
    export default{
        name:"SingleBlogPage",
        props:['event'],
        methods:{

        }
    }
</script>