<template>
    <Header/>
        <Banner name="Events" message="Check our latest events" />
        <div class="section section-padding mt-n1">
            <div class="container">
                <!-- Section Title Start -->
                <div v-if="this.$route.name != 'Blog'" class="section-title shape-03 text-center">
                    <h5 class="sub-title">Latest Events</h5>
                    <h2 class="main-title">Educational Events & <span> Workshop</span></h2>
                </div>
                <!-- Section Title End -->

                <!-- Blog Wrapper Start -->
                <div class="blog-wrapper">
                    <div class="row" v-if="eventList.length">
                        <div  v-for="ev in eventList" :key="ev.id" class="col-lg-4 col-md-6">
                            <div class="single-blog">
                                <div class="blog-image">
                                    <!-- <a href="javascript:;">
                                        <img :src="ev.cover_photo" alt="Event">
                                    </a> -->
                                    <router-link :to="'/events/'+ev.type">
                                        <img :src="ev.cover_photo" alt="Event">
                                    </router-link>
                                </div>
                                <div class="blog-content">
                                    <h4 class="title"><a href="javascript:;">{{ ev.title }}</a></h4>

                                    <div class="blog-meta">
                                        <span> <i class="icofont-calendar"></i>{{ ev.start_date }}</span>
                                    </div>

                                    <router-link class="btn btn-primary btn-hover-primary" :to="'/events/'+ev.type">
                                        View Details
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center col-lg-12 p-4" v-else>
                        No event(s) yet
                    </div>
                </div>
                <!-- Blog Wrapper End -->
            </div>
        </div>        
    <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import SingleEvent from '@/components/Events/SingleEvent.vue';
import axios from 'axios';

export default{
    name:"Events",
    components:{
        Header,Banner,Footer,SingleEvent
    },
    data() {
        return {
            details: {},
            loading: false,
            eventList: [],
            evnts:[                {
                    name:"LSETF-UNDP Workshop",
                    date:"4th March 2022",
                    cover:"https://via.placeholdercom/500"
                },
                {
                    name:"Scheneider Electric Training",
                    date:"4th March 2022",
                    authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",authorName:"Jason Araon",likes :"345",date:"4th March 2021",category:"Arts",
                    cover:"https://via.placeholdercom/500"
                },
                {
                    name:"Plumbing foundation training",
                    date:"4th March 2022",
                    cover:"https://via.placeholdercom/500"
                }
            ]            
        }
    },
    created() {
        this.getEvents()
    },
    methods: {
     getEvents() {
        axios.get('https://api.bhubcollege.com/api/v1/events/get-all')
        .then(response => {
            // console.log(response)
            if(response.data.status) {
                this.eventList = response.data.data
            }
        })
        .catch(err => console.log(err))
     }
    }    
}
</script>